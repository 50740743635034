@property --buppy-blue {
  syntax: '<color>';
  inherits: false;
  initial-value: #0066ff;
}

@property --gubba-green {
  syntax: '<color>';
  inherits: false;
  initial-value: #00FF99;
}

.lookup-container {
  font-family: Roboto;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%; min-width: 100%;
  background-color: #e3edf5;
  // background-image: url(../../public/information_logo.png);
  // background-size: 300px;
  // background-repeat: no-repeat;
  // background-position: center;

  .lookup-panel-container {
    position: absolute;
    bottom: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 30px;
    padding-bottom: 30px;

    .lookup-panel {
      width: 375px;
      height: 300px;
      // background-color: #DFE4EC;
      border-radius: 20px;
      display: flex;
      flex: 1;
      // margin: 10px;
      padding: 3px;
      box-shadow: 5px 5px 10px #797979;
      // background-color: #0066ff;
      background: linear-gradient(120deg,#00ffff,#004aff);
      background-size: 120% 120%;
      animation: gradient-animation 8s ease infinite;

      .lookup-panel-contents {
        display: flex;
        flex-direction: column;
        flex: 1;
        border-radius: 17px;

        .lookup-panel-header {
          padding: 10px;
          background-color: #eef6f988;
          border-radius: 17px 17px 0 0;
          font-size: 20px;
          font-weight: bold;
          color: #000000;

          .lookup-panel-header-number {
            font-size: 32px;
            font-weight: bold;
            color: #000000;
          }
        }

        .lookup-panel-body {
          display: flex;
          flex-direction: column;
          flex: 1;
          padding: 2px 10px;
          color: #727272;
          background-color: #eef6f9;
          border-radius: 0 0 17px 17px;
          width: 100%;
          overflow: hidden;

          .lookup-panel-body-header {
            font-size: 16px;
            color: #a7a7a7;
            font-style: italic;
            width: 100%;
          }
          
          .recently-searched {
            max-width: 100%;
            width: 349px;
            cursor: pointer;
            border: none;
            color: #8d8d8d;
            background-color: transparent;
            font-size: 16px;
            transition: color .2s, transform .2s;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-height: 20px;

            &:hover {
              transform: scale(1.05);
              color: #004b92;
            }

            .recently-searched-product-model, .recently-searched-account-name {
              color: #008cff;
            }

            .recently-searched-product-description {
              color: #8d8d8d;
            }
          }
        }
      }
    
    }
          
    @keyframes gradient-animation {
      0% {
        background-position: 0% 50%;
      }
      50% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0% 50%;
      }
    }
  }

  .lookup-landing {
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: 80px;
    overflow: hidden;
    transition: height .2s, margin-top .2s;
    height: 220px;

    &.shrunk {
      height: 0;
      margin-top: 0;
    }

    .lookup-icon {
      opacity: 0.5;
      max-width: 500px;
    }

    .lookup-description {
      position: absolute;
      top: 110px;
      font-size: 18px;
      font-style: italic;
    }
  }

  .autocomplete-container {
    position: absolute;
    top: 30px;
    width: 500px;
    height: 90%;

    input {
      box-shadow: 0px 0px 10px 5px rgb(185, 185, 185) !important;
    }

    .results-container.shown {
      // background-color: #e3edf5;
      z-index: 1000;
    }

    @media only screen and (max-width: 700px) {
      width: 300px;
    }
  }

  .info-container {
    margin-top: 100px;
    font-family: 'Roboto';
    display: inline-grid;
    grid-template-columns: [line1] minmax(300px, 400px) [line2] minmax(300px, 400px);
    row-gap: 0px;
    width: 100%;
    justify-content: space-evenly;
    padding-bottom: 40px;
    background-color: #e3edf5;

    @media only screen and (max-width: 700px) {
      grid-template-columns: [line1] minmax(200px, 300px) [line2] minmax(200px, 300px);
    }

    .account-header {
      padding-left: 20px;
      padding-top: 10px;
      font-size: 18px;
      grid-column: span 2;

      .account-name {
        font-size: 32px;
        font-weight: bold;
      }
      
    }
    
    .info-card {
      grid-column: span 2;
      grid-row: span 2;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      background-color: rgb(171, 183, 200);
      z-index: 0;
      margin: 15px;
      border-radius: 20px;
      overflow: hidden;
      padding: 10px 20px 20px 20px;
      align-items: flex-start;
      min-width: 250px;
      position: relative;
      flex: 1;
      transition: padding .2s;

      @media only screen and (max-width: 700px) {
        padding: 10px 5px 20px 5px;
      }

      .card-header {
        width: 100%;
        font-size: 20px;
        position: relative;
        z-index: 1;

        @media only screen and (max-width: 700px) {
          padding: 0px 20px 0px 20px;
        }

        b {
          font-weight: bold;
          font-size: 40px;
          margin-right: 10px;
        }

        .card-icon-container {
          z-index: 2;
          position: absolute;
          top: 0px; right: -10px;
          z-index: -1;
          transition: right .2s, color .2s;
          background-color: transparent;
          border: 0;
          color: rgb(34, 166, 255);

          &:hover {
            color: rgb(138, 222, 255);
          }   

          &:active {
            color: rgb(0, 123, 172);
          }
          
          svg {
            color: #DFE4EC;
            font-size: 50px;
          }

          .upload-file-button-icon {
            color: inherit;
            transition: color .2s;

            &:hover {
              color: rgb(137, 137, 255);
            }   

            &:active {
              color: rgb(65, 65, 255);
            }
          }
          
          @media only screen and (max-width: 700px) {
            right: 10px;
          }
        }
      }

      .card-content {
        font-size: 16px;
        color: grey;
        width: 100%;

        canvas {
          min-height: 300px; // Adjust this value as needed
        }

        table {
          width: 100%;
          background-color: #DFE4EC;
          min-width: unset;

          tr {
            transition: background-color .2s;
            &:hover {
              background-color: #9bcfff;
            }

            border: 0;

            td {
              padding-right: 20px;
              font-size: 16px;
              max-width: 600px;

              @media only screen and (max-width: 700px) {
                font-size: 12px;
              }

              input::placeholder {
                color: #313034
              }

              .cell-compartment {
                display: flex; flex-direction: column; flex: 1;

                .div {
                  display: flex; 
                }

                .MuiCheckbox-root {
                  padding: 0;
  
                  .MuiSvgIcon-root { // checkbox
                    font-size: 24px;
                  }
                }
  
                svg {
                  font-size: 30px;
                  color: #008cff;
                }
              }
            }
          }
        }

        .search-input {
          width: 100%;
          padding: 3px 12px;
          border: 1px solid #e0e0e0;
          border-radius: 4px;
          font-size: 14px;
          outline: none;
          transition: border-color 0.2s, box-shadow 0.2s;
          background-color: #f8f9fa;

          &:focus {
            border-color: #0D74FF;
            box-shadow: 0 0 0 2px rgba(13, 116, 255, 0.1);
          }
        }

        .file-table {
          tr {
            display: flex;
          }

          .file-info-container {
            flex: 1;
            display: flex;
            flex-direction: column;

            .file-info {
              margin: 0;

              &.file-uploaded {
                font-size: 14px;
                color: #6d6d6d;
              }
            }
          }

          .file-table-buttons-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            .file-table-button {
              background-color: transparent;
              border: 0;
              padding: 0;
              margin: 0 5px;
              display: flex;
              align-items: center;
              justify-content: center;
              transition: color .2s;
              svg {
                width: 30px;
                height: 30px;
              }
              cursor: pointer;

              &.download-file-button {
                color: #00a547;

                &:hover {
                  color: #00702f;
                }
              }

              &.share-file-button {
                color: #007092;

                &:hover {
                  color: #004b92;
                }
              }

              &.delete-file-button {
                color: #920000;

                &:hover {
                  color: #6d0000;
                }
              }
            }
          }
        }

        .add-new {
          margin: 20px 10px 0 10px;
          display: flex;
          flex: 1;
          justify-content: center;
          padding: 10px;
          border: 3px dashed #868686;
          box-shadow: 0 0 0px #797979;
          font-size: 24px;
          color: black;
          transition: border .5s, box-shadow .5s;

          &:hover {
            // border: 3px solid #868686;
            box-shadow: 0 0 10px #797979;
          }
          
          svg {
            font-size: 34px;
          }
        }
      }

      &.map-container {
        flex: 1;
        height: 300px;
        padding: 0;
        width: auto;
        max-width:none;
        grid-row: span 2;
        grid-column: span 2;

        transition: .2s;

        .address-info-background {
          width: 100%;
          height: 100%;
          position: absolute;
          // display: none;
          justify-content: center;
          align-content: center;
          background-color: rgba($color: #000000, $alpha: 0.5);

          &.shown {
            display: flex;
          }

          .address-info-container {
            display: flex;
            flex: 1;
            flex-direction: column;
            margin: 50px;
            background-color: white;
            position: relative;
            justify-content: center;
            align-items: center;
            padding: 15px;
            border-radius: 15px;
            pointer-events: none!important;

            .close {
              background: transparent;
              color: red;
              border: 0;
              height: 40px; width: 40px;
              position: absolute;
              right: 0; top: 0;

              svg {
                font-size: 30px;
              }
            }

            .address {
              font-size: 18px;
              font-weight: bold;
            }

            .maps-link {
              margin-bottom:10px;
            }

            .system-count {
              font-size: 18px;
            }
          }
        }
      }
    }

    .product-container {
      grid-column: span 2;
      display: flex;
      flex-direction: row;
      padding: 20px;

      .product-image {
        width: 200px;
        height: 200px;
        border-radius: 30px;
        color: #00000000;
        object-fit: contain;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
        border: 8px solid #fff;
        transition: all 0.3s ease;

        &:hover {
          transform: scale(1.05);
          box-shadow: 0 8px 20px rgba(0, 0, 0, 0.4);
        }

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: linear-gradient(135deg, rgba(255,255,255,0.3) 0%, rgba(255,255,255,0) 50%);
          border-radius: 7px;
          pointer-events: none;
        }

        &.empty {
          background-image: url("../../public/placeholder.png");
          background-size: contain;
        }
      }

      .product-information {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding-left: 20px;
        font-size: 24px;

        .model {
          font-size: 30px;
          font-weight: bold;
        }

        .description {
          font-size: 20px;
          font-style: italic;
          display: flex;
          flex: 1;
        }

        a.button.sds {
          margin: 15px 0;
          height: 50px;
          font-size: 20px;
          padding: 10px 20px;
          display: flex;
          align-items: center;
          width: 220px;
          cursor: pointer;
          
          .sds-icon {
            font-size: 30px;
            position: absolute;
            right: 10px;
          }
        }

        .options-container {
          font-size: 18px;
          font-weight: bold;
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 5px;

          .product-option {
            font-size: 18px;
            margin: 0 5px;
            border: 2px solid rgb(78, 108, 255);
            border-radius: 5px;
            padding: 0 5px;
            min-width: 30px;
            text-align: center;
            background-color: rgb(235, 247, 255);
          }
        }
      }
    }

    .button {
      border-radius: 15px;
      margin: 15px;
      padding: 20px 15px 20px 20px;
      font-size: 24px;
      position: relative;
      transition: background-color .2s, color .2s, box-shadow .2s;
      box-shadow: 0 0 10px #797979;

      @media only screen and (max-width: 700px) {
        font-size: 18px;
        padding: 10px 15px;
      }

      &:hover {
        color: white !important;
      }

      &:active {
        box-shadow: none;
      }

      .button-icon {
        position: absolute;
        font-size: 50px;
        top: 12px; right: 5px;
        color: inherit;

        @media only screen and (max-width: 700px) {
          font-size: 30px;
          top: 8px; right: 5px;
        }
      }

      &.view-contract, &.sds {
        background-color: #004b92;
        color: #9bcfff;
      }

      &.view-log, &.pds {
        background-color: #00924e;
        color: #9bffb9;
      }
    }
  }
}

.modal-backdrop {
  cursor: pointer;
  display: flex;
  position: fixed;
  flex-direction: column;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  top: 0; left: 0;
  align-items: center;
  justify-content: center;
  background-color: #000000a1;

  &.modal-large {
    overflow: scroll;

    .modal {
      max-height: none;
      width: 90%;
      top: 80px;
      margin-bottom: 40px;
      max-width: 800px;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      overflow: visible;

      .modal-content {
        overflow-y: visible;
      }
    }

    .modal-close, .quote-delete-button {
      position: absolute;
      top: 20px;
      // background-color: transparent;
      border-radius: 10px;
      padding: 10px 15px;
      font-size: 18px;
    }

    .modal-close {
      left: 5%;
      border: 2px solid #ffffff;
    }

    .quote-delete-button {
      top: 20px; right: 5%;
      width: 150px;
      background-color: #920000;
      border: 2px solid #c72525;
      color: #ffffff;
    }

    .quote-delete-button:hover {
      background-color: #6d0000;
    }
  }

  .modal {
    display: flex;
    max-width: 90%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 15px;
    position: absolute;

    @media only screen and (max-width: 600px) {
      margin: auto;
      width: 95% !important;
    }

    .modal-content {
      overflow-y: scroll;
      scrollbar-width: none;
      position: relative;
      border-radius: 15px;

      &.lightgrey-background {
        background-color: #9ea9ba;
      }

      h3 {
        text-align: center;
      }

      canvas {
        width: 50%;
        height: 50%;
        // max-width: 300px;
        height: auto !important;
        width: auto !important;
        max-height: 300px;
        overflow-y: auto;

        @media only screen and (max-width: 700px) {
          max-height: 200px;
        }
        // padding: 2rem;
      }

      .chart-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 2rem;

        .charts-row {
          width: 100%;
          display: flex;
          flex-direction: row;
          // flex-wrap: wrap;
          justify-content: center;
          // gap: 2rem;

          .chart-wrapper {
            width: 50%;
            // max-width: 400px;
            // min-width: 300px;
            // flex: 1 1 300px;
          }
        }

        .shared-legend {
          margin-top: 1rem;

          ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 1rem;
            padding: 0;
            margin: 0;
            list-style: none;

            li {
              display: flex;
              align-items: center;
              margin-right: 10px;

              .color-box {
                display: inline-block;
                width: 12px;
                height: 12px;
                margin-right: 5px;
              }

              .legend-text {
                font-size: 11px;
              }
            }
          }
        }
      }

      .quote-wizard {
        background-image: url("../../public/PROASYS_4C_notag.png");
        background-repeat: no-repeat;
        background-position: center 20px;
        background-size: 70%;
        background-color: white;
        border-radius: 15px;
        width: 800px;
        max-width: 100%;
        padding: 160px 50px 10px 50px;
        cursor: default;
        position: relative;

        @media only screen and (max-width: 600px) {
          padding: 160px 20px 10px 20px;
        }

        .quote-wizard-watermark {
          position: absolute;
          top: 30px; left: 0;
          width: 100%; height: 100%;
          background-image: url("../../public/proasys_alt.png");
          background-size: 50%;
          background-repeat: no-repeat;
          background-position: center;
          opacity: 0.07;
          z-index: 0;
        }

        .quote-wizard-header {
          position: relative;
          z-index: 1;
          margin-bottom: 40px;
          color: #737373;

          .quote-wizard-header-recipients {
            cursor: pointer;
            text-decoration: underline;
            font-style: italic;

            &:hover {
              color: var(--buppy-blue);
            }
          }
        }

        .quote-wizard-body { 
          position: relative;
          z-index: 1;
          .quote-form {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .quote-form-row {
              display: flex;
              flex-direction: row;
              // justify-content: center;
              gap: 10px;
              position: relative;

              .quote-form-field {
                flex: 1;
                box-shadow: none;
                padding-bottom: 2px;
                position: relative;
                border-bottom-width: 0 !important;
                background-repeat: no-repeat !important;
                background-position: 0% 100%;
                box-shadow: none !important;
                -webkit-box-shadow: none !important;
                background-size: 300% 2px !important;
                background-image: linear-gradient(90deg, var(--buppy-blue), var(--buppy-blue));
                @keyframes background-move {
                  0% { background-position: 100% 100%; }
                  100% { background-position: 0% 100%; }
                }

                &.centered-text {
                  text-align: center;
                }

                &.product-field {
                  height: 20px;
                  padding-bottom: 2px;
                  margin-bottom: 0px;

                  @media only screen and (max-width: 600px) {
                    font-size: 14px;
                  }
                }

                &.body-field {
                  margin-bottom: 40px;
                }

                &.saved {
                  background-image: linear-gradient(90deg, var(--buppy-blue), var(--buppy-blue), var(--gubba-green), var(--buppy-blue), var(--buppy-blue));
                  animation: background-move .7s linear forwards !important;
                }

                &.short-field {
                  flex: 0;
                  width: 100px;
                  text-align: center;
                  height: 30px;
                }
              }

              .quote-form-field-label {
                display: flex;
                align-items: center;
                font-size: 18px;
              }

              .product-suggestions-container {
                flex: 1;
                display: flex;
                flex-direction: row;
                gap: 10px;
                overflow-x: scroll;
                padding-bottom: 10px;

                .product-suggestion {
                  display: inline-flex;
                  flex-direction: column;
                  text-align: center;
                  flex: 0;
                  border: 1px solid #000000;
                  border-radius: 5px;
                  font-size: 12px;
                  white-space: nowrap;
                  padding: 5px 10px;
                  border: 1px solid #000000;
                  border-radius: 20px;
                  background-color: #f0f0f0;
                  cursor: pointer;
                  transition: background-color .2s;
                  // box-shadow: 0 0 5px #000000;

                  &:hover {
                    background-color: #ffffff;
                  }

                  .product-suggestion-model {
                    font-weight: bold;
                    color: #002f8e;
                  }

                  .product-suggestion-description {
                    font-size: 10px;
                    color: black;
                  }
                }
              }

              .quote-form-services_container {
                display: flex;
                flex-direction: column;
                flex: 1;
                margin-bottom: 20px;

                .quote-form-service {
                  background-color: #d8e4fa;
                  color: #002f8e;
                  border: 2px solid #002f8e;
                  border-radius: 5px;
                  padding: 5px 10px;
                  margin-bottom: 5px;
                  transition: background-color .2s, color .2s;

                  &:hover {
                    background-color: #ffffff;
                    color: #8e005a;
                  }
                }
              }
            }

            .quote-form-button {

              &.add-product-button {
                width: 50%;
                padding: 10px;
                font-size: 18px;
                color: #002f8e;
                font-weight: bold;
                background-color: rgba(255, 255, 255, 0.7);
                border: 2px dashed #002f8e;
                border-radius: 5px;
                margin-bottom: 40px;
              }

              &.remove-product-button {
                width: 20px;
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #a0a0a0;
                // background-color: #ffb2b2;
                background-color: rgb(255, 255, 255);
                border-radius: 25%;
                padding: 10px;
                color: #990000;
                font-weight: bold;
                box-shadow: 2px 2px 2px #a8a8a8;
                transition: background-color .2s, box-shadow .2s;
                svg {
                  width: 16px;
                  height: 16px;
                }

                &:hover {
                  background-color: #ffb2b2;
                  box-shadow: 0px 0px 0px #a8a8a8;
                }
              }
            }
          }
        }

        .quote-wizard-footer {
          width: 100%;
          margin-top: 40px;
          padding: 10px 10px 20px 10px;
          font-size: 12px;
          text-align: center;
          color: #002f8e;
        }
      }

      .quote-wizard-buttons-container {
        display: flex;
        flex-direction: row;
        gap: 10px;
        padding: 10px;
        position: relative;
        z-index: 1;

        .quote-wizard-button {
          width: 50%;
          padding: 10px;
          font-size: 18px;
          border-radius: 10px;
          box-shadow: 0 0 5px #000000;
          transition: box-shadow .2s, transform .2s;

          &:hover {
            box-shadow: 0 0 7px #000000;
            transform: scale(1.01);
          }

          &:active {
            box-shadow: 0 0 0 #000000;
          }

          &.preview-button {
            background-color: #004b92;
            text-align: center;
            color: #9bcfff;
            border: 2px solid #9bcfff;
          }

          &.send-button, &.select-recipients-button {
            background-color: #00924e;
            color: #9bffb9;
            border: 2px solid #9bffb9;
          }
        }
      }

      .success-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        .success-row {
          display: flex;
          flex-direction: row;
          gap: 10px;

          button, a {
            width: 200px;
            padding: 10px 20px;
            font-size: 16px;
            border-radius: 8px;
            background-color: #ffffff;
            color: #333333;
            border: 2px solid #cccccc;
            cursor: pointer;
            transition: all 0.2s ease;
            text-decoration: none;
            text-align: center;

            &:hover {
              background-color: #f5f5f5;
              border-color: #999999;
              transform: translateY(-1px);
              box-shadow: 0 2px 4px rgba(0,0,0,0.1);
            }

            &:active {
              transform: translateY(0);
              box-shadow: none;
            }
          }

          .close-button:hover {
            background-color: var(--buppy-blue);
            color: #ffffff;
            border: 2px solid #d1e4ff;
          }

          .preview-button:hover {
            background-color: #009592;
            color: #ffffff;
            border: 2px solid #adffde;
          }
        }
      }
    }
  }
};

.recipient-select-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  
  .recipient-select-row {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
    // background-color: #bababa;
    padding: 5px 20px;

    .recipient-select-row-checkbox {
      flex: 0;
      margin-left: 5px;
    }

    .recipient-select-row-info {
      display: flex;
      flex-direction: column;
      flex: 1;
      font-size: 16px;
    }

    .recipient-select-add-icon {
      flex: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--buppy-blue);
      margin-right: 10px;
      transition: color .2s, transform .2s;

      &.disabled {
        color: #737373;
        cursor: default;
        opacity: 0.5;
      }

      &:hover:not(.disabled) {
        color: #009dff;
        transform: scale(1.1);
      }
    }
  }

  .recipient-select-row-buttons {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
}